import React from 'react';
import angelinaPhoto from '../media/angelina.jpg';
import photo1 from '../media/img-1.png';
import p3StockPhoto5 from '../media/p3StockPhoto5.jpg';

export default function MobileAbout() {
    return(
        <div className="About flexColumnCenterCenter">
            
            <div className="aboutContent">
                <div className='flexColumnCenterCenter'>
                    <h1 id="aboutH1" className='satisfy200 textAlignLeft'>Perfect Party Planner</h1>
                    <img src={angelinaPhoto} alt="Angelina Myler, Founder" id='angelinaPortrait' />
                    <h4 className="aboutText1 textAlignLeft poppins400">Angelina Myler, the founder of Perfect Party Planner, has had a lifelong passion for throwing unforgettable parties. Angelina thrives when guests arrive at an event with a look of excitement on their face, knowing they are about to experience special moments surrounded by great imagination and seamless implementation.<br/><br/>The decision to create Perfect Party Planner came with Angelina's realization that when she was behind the scenes of her own parties, ensuring everything was running smoothly, she was missing out on most of the fun. At Perfect Party Planner, we believe that the host/hostess should be able to enjoy the party as much as the guests (and leave the hard work to the Party Planner), so they can also make lasting memories and be present in the moment. </h4>
                </div>
            </div>
            <div className="aboutSec2">
                <img id="aboutPhotoLeft" src={p3StockPhoto5} alt=""/>
                <div className="quote borderLeft borderRight">
                    <h1 className="aboutText2">“My family has always loved large, LOUD gatherings for any and all occasions! We still reminisce about the great parties we’ve had. When I got older, I wanted to continue that family tradition. I have always loved to hear the “ooh’s” and “ahh’s” when guests walk into my parties, but I found myself missing out on most of the party when I was handling everything behind the scenes. That’s when I realized something had to change!”</h1>
                    <h4 className="aboutText">-Angelina Myler, founder of Perfect Party Planner</h4>
                </div>
            </div>
            <img id="aboutPhoto" src={photo1} alt=""/>
            
        </div>
    )
}