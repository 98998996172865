import React from 'react';
import phoneIcon from '../media/phone.png';
import checklistIcon from '../media/checklist.png';
import partyIcon from '../media/party.png';
import servicesVideo from '../media/servicesVideo.mp4';


export default function MobileServices() {
    return(
        <div className="flexColumnCenterCenter">
                <div className="Services flexColumnCenterCenter">
                        <h1  className="satisfy200 servicesTitle width60">Save time and avoid the stress of planning your perfect party with our customizable party planning services</h1>
                        <h4 className="satisfy200">How it works:</h4>
                    <div className='stepsContainer'>
                        <div className="step flexColumnCenterCenter">
                            <img className="stepIcon" src={phoneIcon} alt=''/>
                            <h1>1. Tell us about your party</h1>
                            <h4>Share your ideas, wishes, and desires.</h4>
                        </div>
                        <div className="step flexColumnCenterCenter">
                            <img className="stepIcon" src={checklistIcon} alt=''/>
                            <h1>2. Receive a custom party plan</h1>
                            <h4>We create a party plan specific to you.</h4>
                        </div>
                        <div className="step flexColumnCenterCenter">
                            <img className="stepIcon" src={partyIcon} alt=''/>
                            <h1>3. Host an unforgettable party</h1>
                            <h4>Enjoy the "ooh's" and "ahh's" of your guests.</h4>
                        </div>
                    </div>
                </div>
                <div className="flexColumnCenterCenter backgroundVideo">
                        <video autoPlay muted loop id="video">
                            <source src={servicesVideo} type="video/mp4" />
                        </video>
                        <h1 className='satisfy200 fontColorGreen servicesTitle'>Planning and executing<br/>the perfect party<br/>is a full time job</h1>
                </div>
                <div className="servicesContent flexColumnCenterCenter">
                    <div className="servicesList">
                        <ul className="textAlignLeft">
                            <h4 className="handlee400">Graduations</h4>
                            <h4 className="handlee400">Bridal Showers</h4>
                            <h4 className="handlee400">Gender Reveal Parties</h4>
                            <h4 className="handlee400">Pool Parties</h4>
                            <h4 className="handlee400">Theme Parties</h4>
                            <h4 className="handlee400">Corporate Events</h4>
                            <h4 className="handlee400">Anniversaries</h4>
                            <h4 className="handlee400">Baby Showers</h4>
                            <h4 className="handlee400">Bachelorette Parties</h4>
                            <h4 className="handlee400">Engagement Parties</h4>
                            <h4 className="handlee400">Girl’s Weekend</h4>
                            <h4 className="handlee400">Holiday Parties</h4>
                            <h4 className="handlee400">Team Building Events</h4>
                        </ul>
                    </div>
                    <div className='flexColumnCenterCenter width80'>
                        <h4 id="" className='textAlignLeft poppins400'>
                            <br/>
                            No matter how fast you work or how productive you are, there are only so many hours in a day. You want to spend that time with your loved ones, creating lasting memories. But when it comes to throwing a party, you’re often left feeling stressed, overwhelmed and missing the fun.<br/><br/>Our services begin with an onsite consultation where we dive into your vision for a perfect party. After that, we invite you to be as involved (or uninvolved) as you'd like to be. We know how busy life can be, so rest easy knowing we’ll walk along side you and be a contributing partner to your event planning - or if you prefer, we’re happy to take care of everything for you. From invitations to thank you gifts, we’re here to bring your party vision to life.<br/><br/>That’s why we create and execute custom party plans based on your specifications. If requested, we'll also stay on-site throughout your party to ensure everything runs smoothly.
                        </h4>
                        <a className="detailsButton" href='/contact'>CONTACT US</a>

                    </div>

                 </div>
            </div>
    )
}