import React from 'react';
import ContactForm from '../ContactForm';

export default function Contact() {
    return(
        <div className="pageContainer">
            <div id="contactContent" className="Contact flexRowBetweenStart">

                <div className="flexColumnCenterCenter contactSection width50">
                    <h4>We are here to help with all your party and event needs.  Since our services are fully customizable, we will need to learn a bit about you and your event.<br/><br/>Please take a moment to fill out the form below to share your party and event needs with us.</h4>
                    <a className="detailsButton" href='https://www.honeybook.com/widget/perfect_party_planner_189129/cf_id/60de625e6c1a59356aa2ff84'>CONTACT US</a>
                </div>
                
            </div>
        </div>
    )
}