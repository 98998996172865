import React from "react";
import logo from './media/logoNov22.png';

export default function Header() {
    return(
      <div className="header flexRowRightCenter headerSolid">
        <div className="headerLeft flexRowCenterCenter">
            <a href="/"><img className="icon" src={logo} alt="Perfect Party Planner"/></a>
            <a href="/" className="textDecorationNone">
                <div className="titleContainer flexColumnCenterCenter">
                <h1 className="titleFont fontColorBlack textAlignLeft">Perfect Party Planner</h1>
                <h1 className="titleFont fontColorTan textAlignLeft">We work, you enjoy!</h1>

                </div>
            </a>
        </div>
        <div className="navbar flexRowRightCenter">
            <a href='/services' className="textDecorationNone"><h1 className="fontColorBlack navLink">Our Services</h1></a>
            <a href='/gallery' className="textDecorationNone"><h1 className="fontColorBlack navLink">Gallery</h1></a>
            <a href='/about' className="textDecorationNone"><h1 className="fontColorBlack navLink">About Us</h1></a>
        <a href='/contact' className="textDecorationNone"><h1 className="fontColorBlack navLink">Contact</h1></a>
        </div>
      </div>
    );
}