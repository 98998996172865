import React from 'react';

export default function CollagePhoto(props) {
    return(
        <div id='collageContainer'>
            <img alt='' id='collageImage' src={props.image} />
        </div>
    )
}

