import React from 'react';
import CollagePhoto from '../components/CollagePhoto';
import image1 from '../media/collage_photos/img1.jpg';
import image2 from '../media/collage_photos/img2.jpg';
import image3 from '../media/collage_photos/img3.jpg';
import image4 from '../media/collage_photos/img4.jpg';
import image5 from '../media/collage_photos/img5.jpg';
import image6 from '../media/collage_photos/img6.jpg';
import image7 from '../media/collage_photos/img7.jpg';
import image9 from '../media/collage_photos/img9.jpg';
import image10 from '../media/collage_photos/img10.jpg';
import image12 from '../media/collage_photos/img12.jpg';
import image13 from '../media/collage_photos/img13.jpg';
import image14 from '../media/collage_photos/img14.jpg';
import image15 from '../media/collage_photos/img15.jpg';
import image16 from '../media/collage_photos/img16.jpg';
import image17 from '../media/collage_photos/img17.jpg';
import image18 from '../media/collage_photos/img18.jpg';
import image20 from '../media/collage_photos/img20.jpg';
import image22 from '../media/collage_photos/img22.jpg';
import image23 from '../media/collage_photos/img23.jpg';
import image24 from '../media/collage_photos/img24.jpg';
import image25 from '../media/collage_photos/img25.jpg';
import image26 from '../media/collage_photos/img26.jpg';
import image27 from '../media/collage_photos/img27.jpg';
import image28 from '../media/collage_photos/img28.jpg';
import image29 from '../media/collage_photos/img29.jpg';
import image30 from '../media/collage_photos/img30.jpg';
import image31 from '../media/collage_photos/img31.jpg';
import image32 from '../media/collage_photos/img32.jpg';
import image33 from '../media/collage_photos/img33.jpg';
import image34 from '../media/collage_photos/img34.jpg';
import image35 from '../media/collage_photos/img35.jpg';
import image36 from '../media/collage_photos/img36.jpg';
import image37 from '../media/collage_photos/img37.jpg';
import image38 from '../media/collage_photos/img38.jpg';
import image39 from '../media/collage_photos/img39.jpg';
import image40 from '../media/collage_photos/img40.jpg';
import image41 from '../media/collage_photos/img41.jpg';
import image42 from '../media/collage_photos/img42.jpg';
import image43 from '../media/collage_photos/img43.jpg';
import image44 from '../media/collage_photos/img44.jpg';
import image45 from '../media/collage_photos/img45.jpg';
import image46 from '../media/collage_photos/img46.jpg';
import image47 from '../media/collage_photos/img47.jpg';
import image48 from '../media/collage_photos/img48.jpg';
import image49 from '../media/collage_photos/img49.jpg';
import image50 from '../media/collage_photos/img50.jpg';
import image51 from '../media/collage_photos/img51.jpg';
import image52 from '../media/collage_photos/img52.jpg';
import image53 from '../media/collage_photos/img53.jpg';
import image54 from '../media/collage_photos/img54.jpeg';
import image55 from '../media/collage_photos/img55.jpeg';
import image56 from '../media/collage_photos/img56.jpeg';
import image57 from '../media/collage_photos/img57.jpg';
import image58 from '../media/collage_photos/img58.jpg';
import image59 from '../media/collage_photos/img59.jpg';
import image60 from '../media/collage_photos/img60.jpg';
import image61 from '../media/collage_photos/img61.jpg';
import image62 from '../media/collage_photos/img62.jpg';
import image63 from '../media/collage_photos/img63.jpg';
import image64 from '../media/collage_photos/img64.jpg';
import image65 from '../media/collage_photos/img65.jpg';
import image66 from '../media/collage_photos/img66.jpg';
import image67 from '../media/collage_photos/img67.jpg';
import image68 from '../media/collage_photos/img68.jpg';
import image69 from '../media/collage_photos/img69.jpg';
import image70 from '../media/collage_photos/img70.jpg';
import image71 from '../media/collage_photos/img71.jpg';
import image72 from '../media/collage_photos/img72.jpg';
import image73 from '../media/collage_photos/img73.jpg';
import image74 from '../media/collage_photos/img74.jpg';
import image75 from '../media/collage_photos/img75.jpg';
import image76 from '../media/collage_photos/img76.jpg';
import image77 from '../media/collage_photos/img77.jpg';
import image78 from '../media/collage_photos/img78.jpg';
import image79 from '../media/collage_photos/img79.jpg';

export default function MobileGallery() {
    return(
        <div id="Gallery" className="flexColumnAroundStart">
        <div id="testimonialContainer" className="flexColumnCenterCenter">
                <div className="testimonial">
                    <h4 className="testimonialText textAlignLeft">“I have attended a baby shower, graduation party and theme party. A lot of thought and care went into each and every one of them. You can’t go wrong using Perfect Party Planner for your event. I’m attending a baby’s 1st birthday party soon and know that one will be extra special also!”</h4>
                    <h4 className="testimonialText textAlignLeft">-Linda G.</h4>
                </div>
                <div className="testimonial">
                    <h4 className="testimonialText textAlignLeft">“Just wanted to give a huge shout out to Perfect Party Planner for the wonderful 60th birthday party they planned for my hubby. Initially, prior to Covid, it was going to be a huge event with family and friends coming in from out of town and of course in the area, as well.<br/><br/>Plans had to change. It scaled down quite a bit. Mostly just immediate family but it was still totally wonderful! They did a wonderful job with decorating, setting up, serving, cleaning up and a fun time was had by all. I would totally recommend them and will use their party planning again for our next event. Thank you again for a job well done, professionally and courteously.”</h4>
                    <h4 className="testimonialText textAlignLeft">-Teri B.</h4>
                </div>
            </div>

        <h1 className="titleFont">Gallery</h1>
            <div id="collage" className='flexRowAroundCenter'>
            <CollagePhoto image={image68}/>
                <CollagePhoto image={image69}/>
                <CollagePhoto image={image70}/>
                <CollagePhoto image={image71}/>
                <CollagePhoto image={image72}/>
                <CollagePhoto image={image73}/>
                <CollagePhoto image={image74}/>
                <CollagePhoto image={image75}/>
                <CollagePhoto image={image76}/>
                <CollagePhoto image={image77}/>
                <CollagePhoto image={image78}/>
                <CollagePhoto image={image79}/>
                <CollagePhoto image={image64}/>
                <CollagePhoto image={image65}/>
                <CollagePhoto image={image66}/>
                <CollagePhoto image={image67}/>
                <CollagePhoto image={image1}/>
                <CollagePhoto image={image2}/>
                <CollagePhoto image={image3}/>
                <CollagePhoto image={image4}/>
                <CollagePhoto image={image5}/>
                <CollagePhoto image={image6}/>
                <CollagePhoto image={image7}/>
                <CollagePhoto image={image9}/>
                <CollagePhoto image={image10}/>
                <CollagePhoto image={image12}/>
                <CollagePhoto image={image13}/>
                <CollagePhoto image={image14}/>
                <CollagePhoto image={image15}/>
                <CollagePhoto image={image16}/>
                <CollagePhoto image={image17}/>
                <CollagePhoto image={image18}/>
                <CollagePhoto image={image20}/>
                <CollagePhoto image={image22}/>
                <CollagePhoto image={image23}/>
                <CollagePhoto image={image24}/>
                <CollagePhoto image={image25}/>
                <CollagePhoto image={image26}/>
                <CollagePhoto image={image27}/>
                <CollagePhoto image={image28}/>
                <CollagePhoto image={image29}/>
                <CollagePhoto image={image30}/>
                <CollagePhoto image={image31}/>
                <CollagePhoto image={image32}/>
                <CollagePhoto image={image33}/>
                <CollagePhoto image={image34}/>
                <CollagePhoto image={image35}/>
                <CollagePhoto image={image36}/>
                <CollagePhoto image={image37}/>
                <CollagePhoto image={image38}/>
                <CollagePhoto image={image39}/>
                <CollagePhoto image={image40}/>
                <CollagePhoto image={image41}/>
                <CollagePhoto image={image42}/>
                <CollagePhoto image={image43}/>
                <CollagePhoto image={image44}/>
                <CollagePhoto image={image45}/>
                <CollagePhoto image={image46}/>
                <CollagePhoto image={image47}/>
                <CollagePhoto image={image48}/>
                <CollagePhoto image={image49}/>
                <CollagePhoto image={image50}/>
                <CollagePhoto image={image51}/>
                <CollagePhoto image={image52}/>
                <CollagePhoto image={image53}/>
                <CollagePhoto image={image54}/>
                <CollagePhoto image={image55}/>
                <CollagePhoto image={image56}/>
                <CollagePhoto image={image57}/>
                <CollagePhoto image={image58}/>
                <CollagePhoto image={image59}/>
                <CollagePhoto image={image60}/>
                <CollagePhoto image={image61}/>
                <CollagePhoto image={image62}/>
                <CollagePhoto image={image63}/>
            </div>

        </div>
    )
}