import React, { useEffect, useState } from "react";
import logo from './media/logoNov22.png';

export default function Header() {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
    }
  }, []);


    return(
      <div className={`header flexRowRightCenter ${small ? "headerSolid" : "headerTransparent"}`}>
        <div className="headerLeft">
            <a href="/"><img className="icon" src={logo} alt="Perfect Party Planner"/></a>
            <a href="/" className="textDecorationNone">
                <div className="titleContainer flexColumnCenterCenter">
                <h1 className={`${small ? "titleFont fontColorBlack textAlignLeft" : "titleFont fontColorGreen textAlignLeft"}`}>Perfect Party Planner<br/></h1>
                <h1 className={`${small ? "titleFont fontColorTan textAlignLeft" : "titleFont fontColorTan textAlignLeft"}`}>We work, you enjoy!</h1>
                </div>
                
            </a>
        </div>
        <div className="navbar flexRowRightCenter">
            <a href='/services' className="textDecorationNone"><h1 className={`navLink ${small ? "fontColorBlack" : "fontColorGreen"}`}>Our Services</h1></a>
            <a href='/gallery' className="textDecorationNone"><h1 className={`navLink ${small ? "fontColorBlack" : "fontColorGreen"}`}>Gallery</h1></a>
            <a href='/about' className="textDecorationNone"><h1 className={`navLink ${small ? "fontColorBlack" : "fontColorGreen"}`}>About Us</h1></a>
        <a href='/contact' className="textDecorationNone"><h1 className={`navLink ${small ? "fontColorBlack" : "fontColorGreen"}`}>Contact</h1></a>
        </div>
      </div>
    )
}