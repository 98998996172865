import React from 'react';
import placeholderVideo from '../placeholder.mp4';
import p3StockPhoto1 from '../media/p3StockPhoto1.jpg';
import p3StockPhoto2 from '../media/p3StockPhoto2.jpg';

export default function Home() {

            return(
                <div className="Home">
                    
                    <div className="landing flexColumnCenterCenter">
                        <video autoPlay playsinline muted loop id="video">
                        <source src={placeholderVideo} type="video/mp4" />
                        </video>
                        <div className="landingContent flexColumnCenterStart">
                            <h1 id="homeTitle" className="fontColorGreen hubballi400">Memories from Moments</h1>
                            <h4 className="fontColorGreen">Taking the stress out of party planning</h4>
                            <a className="detailsButton" href='/services'>OUR SERVICES</a>
                        </div>
                    </div>
                    <div className='sec23Container'>
                        <div className="sec3 flexRowAroundCenter">
                            <div className="sec3Left">
                                <img id="homePhoto" src={p3StockPhoto1} alt=""/>
                            </div>
                            <div className="sec3Right flexColumnCenterCenter">
                                <h1 className="hubballi400 textAlignRight fontColorBlack">Avoid the stress of party planning</h1>
                                <h4>Get unique, customized party plans</h4>
                                <h4>Spend your time doing what you enjoy</h4>
                                <h4>Be a guest at your own party</h4>
                                <a className="detailsButton" href='/services'>OUR SERVICES</a>
                            </div>
                            <div className="sec3Left">
                                <img id="homePhoto" src={p3StockPhoto2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
        )

}