import React from "react";
import placeholderVideo from '../placeholder.mp4';
import p3StockPhoto1 from '../media/p3StockPhoto1.jpg';
import p3StockPhoto2 from '../media/p3StockPhoto2.jpg';


export default function MobileHome() {
    
    return (
        <div className="home">
            <div className="landing flexColumnCenterCenter">
                <video autoPlay playsinline muted loop id="video">
                    <source src={placeholderVideo} type="video/mp4" />
                </video>
                <div className="landingContent flexColumnCenterCenter">
                    <h1 className="satisfy200 fontColorGreen">Memories<br/>from<br/>Moments</h1>
                    <h4 className="fontColorGreen">Taking the stress<br/>out of party planning</h4>
                    <a className="detailsButton" href='/services'>OUR SERVICES</a>
                </div>
            </div>
            <div className="sec3 flexRowAroundCenter">
                            <div className="sec3Left">
                                <img id="homePhoto" src={p3StockPhoto1} alt=""/>
                            </div>
                            <div className="sec3Right flexColumnCenterCenter">
                                <h1 className="hubballi400">Avoid the stress of party planning</h1>
                                <h4>Get unique, customized party plans</h4>
                                <h4>Spend your time doing what you enjoy</h4>
                                <h4>Be a guest at your own party</h4>
                                <a className="detailsButton" href='/services'>OUR SERVICES</a>
                            </div>
                            <div className="sec3Left">
                                <img id="homePhoto" src={p3StockPhoto2} alt=""/>
                            </div>
                        </div>
        </div>
    )
}