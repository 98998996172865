import React from "react";
import Hamburger from "../Hamburger";
import logo from '../media/logo.png';

export default function MobileNav() {
    return (
        <div>
            <div className="header flexRowBetweenCenter">
                <div className="headerLeft flexRowAroundCenter">
                    <a href="/"><img className="icon" src={logo} alt="Perfect Party Planner"/></a>
                    <a href="/" className="textDecorationNone"><h1 className="titleFont fontColorBlack">Perfect Party Planner</h1><h1 className="titleFont fontColorTan textAlignLeft">We work, you enjoy!</h1></a>
                </div>
                <Hamburger />
            </div>
        </div>
    )
}

