import React, { useEffect, useState } from "react";
import logo from './media/logo.png';
import heartIcon from './media/heartIcon.png';


export default function Footer() {
    const [windowWidth, setWindowWidth] = useState(0)
  
    useEffect(() => { 
  
     updateDimensions();
  
     window.addEventListener("resize", updateDimensions);
     return () => 
       window.removeEventListener("resize",updateDimensions);
    }, [])
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }
  
    if (windowWidth > 1024) {
        return(
            <div className="footer flexRowBetweenStart">
                <div className="flexRowStartCenter">
                    <div className="footerLeft flexColumnCenterStart">
                        <h1 className="satisfy200 footH1 fontColorGreen">Company</h1>
                        <a href='/about' className='textDecorationNone'><h4 className="footH4">About Us</h4></a>
                        <a href='/services' className='textDecorationNone'><h4 className="footH4">Our Services</h4></a>
                        <a href='/contact' className='textDecorationNone'><h4 className="footH4">Contact</h4></a>
                    </div>
                </div>
                <div className="flexColumnCenterCenter">
                    <div className="flexRowCenterCenter footerCopyright">
                        <div>
                            <h4 className="footH4">With <img id="heartIcon" src={heartIcon} alt="heart icon" /> from Arizona<br/>(480) 673-8207</h4>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="copyright">
                            <h4 className="footH4">© 2021, Perfect Party Planner</h4>
                    </div>
                </div>
                <div className="footerRight flexColumnCenterStart">
                        <h1 className="satisfy200 footH1 fontColorGreen">Follow Us</h1>
                        <a href='https://www.facebook.com/perfectpartyplanneraz' className='textDecorationNone'><h4 className="footH4">Facebook</h4></a>
                        <a href='https://www.instagram.com/perfectpartyplanneraz/' className='textDecorationNone'><h4 className="footH4">Instagram</h4></a>
                    </div>
            </div>
        )
        } else {
            return(
                <div className="footer flexColumnCenterCenter">
                    <div className="sitemap flexRowCenterCenter">
                        <div className="footerContent">
                            <a href='/about' className='textDecorationNone'><h4 className="footH4 textAlignRight">About Us</h4></a>
                            <a href='/services' className='textDecorationNone'><h4 className="footH4 textAlignRight">Our Services</h4></a>
                            <a href='/contact' className='textDecorationNone'><h4 className="footH4 textAlignRight">Contact</h4></a>
                        </div>
                        <a href="/"><img className="footIcon" src={logo} alt="Perfect Party Planner"/></a>
                        <div className="footerContent">
                            <a href='https://www.facebook.com/perfectpartyplanneraz' className='textDecorationNone'><h4 className="footH4 textAlignLeft">Facebook</h4></a>
                            <a href='https://www.instagram.com/perfectpartyplanneraz/' className='textDecorationNone'><h4 className="footH4 textAlignLeft">Instagram</h4></a>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className="footerRight flexColumnCenterCenter">
                        <div className="copyright">
                                <h4 className="footH4Right">© 2021, Perfect Party Planner</h4>
                        </div>
                    </div>
                </div>
            )
        }
}