import React from 'react';
import { push as Menu } from 'react-burger-menu'

export default class Example extends React.Component {
  showSettings (event) {
    event.preventDefault();

  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className='wrapper'>
        <Menu noOverlay right width={ '300px' } pageWrapId={ "page-wrap" }>
          <a id="home" className="menu-item" href="/">Home</a>
          <a id="services" className="menu-item" href="/services">Our Services</a>
          <a id="about" className="menu-item" href="/about">About Us</a>
          <a id="contact" className="menu-item" href="/gallery">Gallery</a>
          <a id="contact" className="menu-item" href="/contact">Contact</a>
        </Menu>
      </div>
    );
  }
}