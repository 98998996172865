import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from '../media/collage_photos/img1.jpg';
import image2 from '../media/collage_photos/img2.jpg';
import image3 from '../media/collage_photos/img3.jpg';
import image4 from '../media/collage_photos/img4.jpg';
import image5 from '../media/collage_photos/img5.jpg';
import image6 from '../media/collage_photos/img6.jpg';
import image7 from '../media/collage_photos/img7.jpg';
import image9 from '../media/collage_photos/img9.jpg';
import image10 from '../media/collage_photos/img10.jpg';
import image12 from '../media/collage_photos/img12.jpg';
import image13 from '../media/collage_photos/img13.jpg';
import image14 from '../media/collage_photos/img14.jpg';
import image15 from '../media/collage_photos/img15.jpg';
import image16 from '../media/collage_photos/img16.jpg';
import image17 from '../media/collage_photos/img17.jpg';
import image18 from '../media/collage_photos/img18.jpg';
import image20 from '../media/collage_photos/img20.jpg';
import image22 from '../media/collage_photos/img22.jpg';
import image23 from '../media/collage_photos/img23.jpg';
import image24 from '../media/collage_photos/img24.jpg';
import image25 from '../media/collage_photos/img25.jpg';
import image26 from '../media/collage_photos/img26.jpg';
import image27 from '../media/collage_photos/img27.jpg';
import image28 from '../media/collage_photos/img28.jpg';
import image29 from '../media/collage_photos/img29.jpg';
import image30 from '../media/collage_photos/img30.jpg';
import image31 from '../media/collage_photos/img31.jpg';
import image32 from '../media/collage_photos/img32.jpg';
import image33 from '../media/collage_photos/img33.jpg';
import image34 from '../media/collage_photos/img34.jpg';
import image35 from '../media/collage_photos/img35.jpg';
import image36 from '../media/collage_photos/img36.jpg';
import image37 from '../media/collage_photos/img37.jpg';
import image38 from '../media/collage_photos/img38.jpg';
import image39 from '../media/collage_photos/img39.jpg';
import image40 from '../media/collage_photos/img40.jpg';
import image41 from '../media/collage_photos/img41.jpg';
import image42 from '../media/collage_photos/img42.jpg';
import image43 from '../media/collage_photos/img43.jpg';
import image44 from '../media/collage_photos/img44.jpg';
import image45 from '../media/collage_photos/img45.jpg';
import image46 from '../media/collage_photos/img46.jpg';
import image47 from '../media/collage_photos/img47.jpg';
import image48 from '../media/collage_photos/img48.jpg';
import image49 from '../media/collage_photos/img49.jpg';
import image50 from '../media/collage_photos/img50.jpg';
import image51 from '../media/collage_photos/img51.jpg';
import image52 from '../media/collage_photos/img52.jpg';
import image53 from '../media/collage_photos/img53.jpg';
import image54 from '../media/collage_photos/img54.jpeg';
import image55 from '../media/collage_photos/img55.jpeg';
import image56 from '../media/collage_photos/img56.jpeg';
import image57 from '../media/collage_photos/img57.jpg';
import image58 from '../media/collage_photos/img58.jpg';
import image59 from '../media/collage_photos/img59.jpg';
import image60 from '../media/collage_photos/img60.jpg';
import image61 from '../media/collage_photos/img61.jpg';
import image62 from '../media/collage_photos/img62.jpg';
import image63 from '../media/collage_photos/img63.jpg';
import image64 from '../media/collage_photos/img64.jpg';
import image65 from '../media/collage_photos/img65.jpg';
import image66 from '../media/collage_photos/img66.jpg';
import image67 from '../media/collage_photos/img67.jpg';
import image68 from '../media/collage_photos/img68.jpg';
import image69 from '../media/collage_photos/img69.jpg';
import image70 from '../media/collage_photos/img70.jpg';
import image71 from '../media/collage_photos/img71.jpg';
import image72 from '../media/collage_photos/img72.jpg';
import image73 from '../media/collage_photos/img73.jpg';
import image74 from '../media/collage_photos/img74.jpg';
import image75 from '../media/collage_photos/img75.jpg';
import image76 from '../media/collage_photos/img76.jpg';
import image77 from '../media/collage_photos/img77.jpg';
import image78 from '../media/collage_photos/img78.jpg';
import image79 from '../media/collage_photos/img79.jpg';
import image80 from '../media/collage_photos/img79.jpg';
import image81 from '../media/collage_photos/img79.jpg';
import image82 from '../media/collage_photos/img79.jpg';
import image83 from '../media/collage_photos/img79.jpg';
import image84 from '../media/collage_photos/img79.jpg';
import image85 from '../media/collage_photos/img79.jpg';
import image86 from '../media/collage_photos/img79.jpg';


export default function Gallery() {
    return(
        <div id="Gallery" className="flexRowCenterStart">
            <div id="photoCarousel">
                <Carousel
                    width="100%" 
                    showArrows="true" 
                    autoPlay="true"
                    autoFocus="true"
                    transitionTime={0.5}
                    infiniteLoop="true"
                    interval={2200}
                    stopOnHover="false"
                    preventMovementUntilSwipeScrollTolerance="true"
                    thumbWidth={100}
                    >
                    <div>
                        <img src={image60} alt=""/>
                    </div>
                    <div>
                        <img src={image61} alt="" />
                    </div>
                    <div>
                        <img src={image62} alt="" />
                    </div>
                    <div>
                        <img src={image63} alt="" />
                    </div>
                    <div>
                        <img src={image64} alt="" />
                    </div>
                    <div>
                        <img src={image65} alt="" />
                    </div>
                    <div>
                        <img src={image66} alt="" />
                    </div>
                    <div>
                        <img src={image67} alt="" />
                    </div>
                    <div>
                        <img src={image68} alt="" />
                    </div>
                    <div>
                        <img src={image69} alt="" />
                    </div>
                    <div>
                        <img src={image70} alt="" />
                    </div>
                    <div>
                        <img src={image71} alt="" />
                    </div>
                    <div>
                        <img src={image72} alt="" />
                    </div>
                    <div>
                        <img src={image73} alt="" />
                    </div>
                    <div>
                        <img src={image74} alt="" />
                    </div>
                    <div>
                        <img src={image75} alt="" />
                    </div>
                    <div>
                        <img src={image76} alt="" />
                    </div>
                    <div>
                        <img src={image77} alt="" />
                    </div>
                    <div>
                        <img src={image78} alt="" />
                    </div>
                    <div>
                        <img src={image79} alt="" />
                    </div>
                    <div>
                        <img src={image1} alt="" />
                    </div>
                    <div>
                        <img src={image2} alt="" />
                    </div>
                    <div>
                        <img src={image3} alt="" />
                    </div>
                    <div>
                        <img src={image4} alt="" />
                    </div>
                    <div>
                        <img src={image5} alt="" />
                    </div>
                    <div>
                        <img src={image6} alt="" />
                    </div>
                    <div>
                        <img src={image7} alt="" />
                    </div>
                    {/* <div>
                        <img src={image8} alt="" />
                    </div> */}
                    <div>
                        <img src={image9} alt="" />
                    </div>
                    <div>
                        <img src={image10} alt="" />
                    </div>
                    {/* <div>
                        <img src={image11} alt="" />
                    </div> */}
                    <div>
                        <img src={image12} alt="" />
                    </div>
                    <div>
                        <img src={image13} alt="" />
                    </div>
                    <div>
                        <img src={image14} alt="" />
                    </div>
                    <div>
                        <img src={image15} alt="" />
                    </div>
                    <div>
                        <img src={image16} alt="" />
                    </div>
                    <div>
                        <img src={image17} alt="" />
                    </div>
                    <div>
                        <img src={image18} alt="" />
                    </div>
                    {/* <div>
                        <img src={image19} alt="" />
                    </div> */}
                    <div>
                        <img src={image20} alt="" />
                    </div>
                    {/* <div>
                        <img src={image21} alt="" />
                    </div> */}
                    <div>
                        <img src={image22} alt="" />
                    </div>
                    <div>
                        <img src={image23} alt="" />
                    </div>
                    <div>
                        <img src={image24} alt="" />
                    </div>
                    <div>
                        <img src={image25} alt="" />
                    </div>
                    <div>
                        <img src={image26} alt="" />
                    </div>
                    <div>
                        <img src={image27} alt="" />
                    </div>
                    <div>
                        <img src={image28} alt="" />
                    </div>
                    <div>
                        <img src={image29} alt="" />
                    </div>
                    <div>
                        <img src={image30} alt="" />
                    </div>
                    <div>
                        <img src={image31} alt="" />
                    </div>
                    <div>
                        <img src={image32} alt="" />
                    </div>
                    <div>
                        <img src={image33} alt="" />
                    </div>
                    <div>
                        <img src={image34} alt="" />
                    </div>
                    <div>
                        <img src={image35} alt="" />
                    </div>
                    <div>
                        <img src={image36} alt="" />
                    </div>
                    <div>
                        <img src={image37} alt="" />
                    </div>
                    <div>
                        <img src={image38} alt="" />
                    </div>
                    <div>
                        <img src={image39} alt="" />
                    </div>
                    <div>
                        <img src={image40} alt="" />
                    </div>
                    <div>
                        <img src={image41} alt="" />
                    </div>
                    <div>
                        <img src={image42} alt="" />
                    </div>
                    <div>
                        <img src={image43} alt="" />
                    </div>
                    <div>
                        <img src={image44} alt="" />
                    </div>
                    <div>
                        <img src={image45} alt="" />
                    </div>
                    <div>
                        <img src={image46} alt="" />
                    </div>
                    <div>
                        <img src={image47} alt="" />
                    </div>
                    <div>
                        <img src={image48} alt="" />
                    </div>
                    <div>
                        <img src={image49} alt="" />
                    </div>
                    <div>
                        <img src={image50} alt="" />
                    </div>
                    <div>
                        <img src={image51} alt="" />
                    </div>
                    <div>
                        <img src={image52} alt="" />
                    </div>
                    <div>
                        <img src={image53} alt="" />
                    </div>
                    <div>
                        <img src={image54} alt="" />
                    </div>
                    <div>
                        <img src={image55} alt="" />
                    </div>
                    <div>
                        <img src={image56} alt="" />
                    </div>
                    <div>
                        <img src={image57} alt="" />
                    </div>
                    <div>
                        <img src={image58} alt="" />
                    </div>
                    <div>
                        <img src={image59} alt="" />
                    </div>
                    <div>
                        <img src={image80}alt="" />
                    </div>
                    <div>
                        <img src={image81} alt="" />
                    </div>
                    <div>
                        <img src={image82} alt="" />
                    </div>
                    <div>
                        <img src={image83} alt="" />
                    </div>
                    <div>
                        <img src={image84} alt="" />
                    </div>
                    <div>
                        <img src={image85} alt="" />
                    </div>
                    <div>
                        <img src={image86} alt="" />
                    </div>
                    
                </Carousel>
            </div>
            




            <div id="testimonialContainer" className="flexColumnCenterCenter">
                <div className="testimonial">
                    <h4 className="testimonialText textAlignLeft">“I have attended a baby shower, graduation party and theme party. A lot of thought and care went into each and every one of them. You can’t go wrong using Perfect Party Planner for your event. I’m attending a baby’s 1st birthday party soon and know that one will be extra special also!”</h4>
                    <h4 className="testimonialText textAlignLeft">-Linda G.</h4>
                </div>
                <div className="testimonial">
                    <h4 className="testimonialText textAlignLeft">“Just wanted to give a huge shout out to Perfect Party Planner for the wonderful 60th birthday party they planned for my hubby. Initially, prior to Covid, it was going to be a huge event with family and friends coming in from out of town and of course in the area, as well.<br/><br/>Plans had to change. It scaled down quite a bit. Mostly just immediate family but it was still totally wonderful! They did a wonderful job with decorating, setting up, serving, cleaning up and a fun time was had by all. I would totally recommend them and will use their party planning again for our next event. Thank you again for a job well done, professionally and courteously.”</h4>
                    <h4 className="testimonialText textAlignLeft">-Teri B.</h4>
                </div>
            </div>

        </div>
    )
}