import {React, useEffect, useState} from "react";
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
//desktop imports
import Navbar from "./Navbar";
import NavBarSolid from './NavBarSolid';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Footer from "./Footer";
//mobile imports
import MobileNav from "./components/MobileNav";
import MobileHome from "./components/MobileHome";
import MobileAbout from "./components/MobileAbout";
import MobileServices from "./components/MobileServices";
import MobileContact from "./components/MobileContact";
import MobileGallery from "./components/MobileGallery";
//styles imports
import './App.css';
import './styles/Navbar.css';
import './styles/Home.css';
import './styles/About.css';
import './styles/Services.css';
import './styles/Contact.css';
import './styles/ContactForm.css';
import './styles/Gallery.css';
import './styles/Footer.css';
import './styles/Hamburger.css';
import './styles/CollagePhoto.css';


export default function App() {

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => { 

    updateDimensions();
 
    window.addEventListener("resize", updateDimensions);
    return () => 
      window.removeEventListener("resize",updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
  };

  //full size windows
  if (windowWidth > 1024) {
  return (

    <Router>

      <div className="App">
        <div className="content">

          <Switch>
            <Route exact path="/">
              <Navbar />
              <Home />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/about">
              <NavBarSolid />
              <About />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/services">
              <NavBarSolid />
              <Services />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/contact">
              <NavBarSolid />
              <Contact />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/gallery">
              <NavBarSolid />
              <Gallery />
            </Route>
          </Switch>

        </div>

        <Footer />

      </div>

    </Router>
  );

  } else {
 
    //mobile devices
    return (

      <Router>
  
        <div className="App">
          <div className="content">
  
            <Switch>
              <Route exact path="/">
                <MobileNav />
                <MobileHome />
              </Route>
            </Switch>
  
            <Switch>
              <Route exact path="/about">
                <MobileNav />
                <MobileAbout />
              </Route>
            </Switch>
  
            <Switch>
              <Route exact path="/services">
                <MobileNav />
                <MobileServices />
              </Route>
            </Switch>
  
            <Switch>
              <Route exact path="/contact">
                <MobileNav />
                <MobileContact />
              </Route>
            </Switch>
  
            <Switch>
              <Route exact path="/gallery">
              <MobileNav />
                <MobileGallery />
              </Route>
            </Switch>
  
          </div>
  
          <Footer />
  
        </div>
  
      </Router>
    );
  }
}